// Application configuration
window.APP_CONFIG = {
    // WebSocket configuration
    WS_ENDPOINT: (() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            return 'ws://localhost:8080/ws';
        }
        if (hostname === 'frontend.dev.auroracommand.ai') {
            return 'wss://frontend.dev.auroracommand.ai/ws';
        }
        // Fallback for other environments
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        return `${protocol}//${hostname}/ws`;
    })(),
    WS_PROTOCOL: 'command-protocol',
    WS_RECONNECT_ATTEMPTS: 10,
    WS_RECONNECT_DELAY: 2000,
    WS_PING_INTERVAL: 30000,

    // API configuration
    API_BASE_URL: (() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            return 'http://localhost:8080/api';
        }
        if (hostname === 'frontend.dev.auroracommand.ai') {
            return 'https://frontend.dev.auroracommand.ai/api';
        }
        return `${window.location.protocol}//${hostname}/api`;
    })(),
    API_VERSION: 'v1',
    API_TIMEOUT: 30000,

    // Authentication configuration
    AUTH_ENABLED: true,
    AUTH_PROVIDER: 'cognito',
    AWS_REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_elgiPTht4',
    CLIENT_ID: '4lp68ka85qlei2d5hv190pdojm',
    IDENTITY_POOL_ID: 'us-west-2:12345678-1234-1234-1234-123456789012',

    // Feature flags
    ENABLE_ANALYTICS: true,
    ENABLE_NOTIFICATIONS: true,
    ENABLE_REAL_TIME_UPDATES: true,

    // Constants
    MAX_RECONNECT_ATTEMPTS: 5,
    RECONNECT_DELAY: 1000,
    PING_INTERVAL: 30000
};
